import React, { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { FaUserCog, FaUserFriends } from "react-icons/fa";
import { NetworkServices } from "network";
import { networkErrorHandeller } from "utils/helper";

type DashboardRespTypes = {
  admin: number;
  employee: number;
};

export const Dashboard: React.FC = (): JSX.Element => {
  const [data, setData] = useState<DashboardRespTypes | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Dashboard.index();
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="grid h-screen place-items-center -mt-28 lg:-mt-10 text-center">
      <div className="w-full">
        <p className="uppercase text-3xl lg:text-4xl font-thin mb-6 text-primary">
          Welcome back !!
        </p>

        <div className="sm:flex justify-center gap-4">
          {/* Admin card */}
          <Card className="w-full md:w-[320px] xl:w-[350px] !p-6 mb-4 sm:mb-0">
            <div className="flex justify-between mb-5">
              <FaUserCog size={22} className="text-primary" />
              <p className="font-bold uppercase text-sm text-muted">Admin</p>
            </div>

            {isLoading && !data && !serverError ? (
              <div className="text-right">
                <ContentPreloader />
              </div>
            ) : null}
            {!isLoading && !serverError && !data ? (
              <div className="text-right">
                <p className="font-bold uppercase text-4xl">0</p>
              </div>
            ) : null}

            {!isLoading && !serverError && data ? (
              <div className="text-right">
                <p className="font-bold uppercase text-4xl">{data.admin}</p>
              </div>
            ) : null}
          </Card>

          {/* Employee card */}
          <Card className="w-full md:w-[320px] xl:w-[350px] !p-6">
            <div className="flex justify-between mb-5">
              <FaUserFriends size={22} className="text-primary" />
              <p className="font-bold uppercase text-sm text-muted">Employee</p>
            </div>

            {isLoading && !data && !serverError ? (
              <div className="text-right">
                <ContentPreloader />
              </div>
            ) : null}
            {!isLoading && !serverError && !data ? (
              <div className="text-right">
                <p className="font-bold uppercase text-4xl">0</p>
              </div>
            ) : null}

            {!isLoading && !serverError && data ? (
              <div className="text-right">
                <p className="font-bold uppercase text-4xl">{data.employee}</p>
              </div>
            ) : null}
          </Card>
        </div>
      </div>
    </div>
  );
};

/* Content ghostloader */
const ContentPreloader: React.FC = (): JSX.Element => {
  return (
    <div className="w-[100px] animate-pulse ml-auto">
      <div className={`w-[100px] !h-[40px] rounded-full bg-slate-200`} />
    </div>
  );
};
