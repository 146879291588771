import { Toastify } from "components/toastify";
import { NetworkErrorType } from "types/error";

/* Set token */
export const setToken = async (token: string): Promise<boolean> => {
  localStorage.setItem("token", token);
  return true;
};

/* Get token */
export const getToken = () => {
  return localStorage.getItem("token");
};

/* Remove token */
export const removeToken = () => {
  localStorage.removeItem("token");
  return true;
};

/* Phone number valid check */
export const isValidPhone = () => {
  const regex = /^(?:\+88|88)?(01[3-9]\d{8})$/i;
  return regex;
};

/* E-mail valid check */
export const isValidEmail = () => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex;
};

/* Active path */
export const isActivePath = (current_url_path: string, route_path: string) => {
  const current_url_path_array = current_url_path.split("/");
  const isAvailablePath = current_url_path_array.find((x) => x === route_path);

  if (isAvailablePath) {
    return true;
  }
  return false;
};

/* Global network error handeller */
export const networkErrorHandeller = (error: any) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errors
  ) {
    error.response.data.errors.map((item: NetworkErrorType) => {
      return Toastify.Error(item.message);
    });
  } else {
    return Toastify.Error("Something going wrong, Try again.");
  }
};

/* Generate array from integer number */
export const arrayFromNumber = (data: number) => {
  const array = [];
  for (let i = 0; i < data; i++) array.push(i);
  return array;
};

/* Gender data */
export const genderList = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Other",
    value: "Other",
  },
];

/* date time parse */
export const dateTimeParse = (data: Date) => {
  const newDate = new Date(data);
  const months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const year = newDate.getFullYear();
  const month = newDate.getMonth();
  const day = newDate.getDay();
  let hour: any = newDate.getHours();
  let minute: any = newDate.getMinutes();
  const second = newDate.getSeconds();
  const ampm = hour >= 12 ? "PM" : "AM";

  hour %= 12;
  hour = hour || 12;
  hour = hour < 10 ? `0${hour}` : hour;
  minute = minute < 10 ? `0${minute}` : minute;

  const modifiedDate = `${day} ${months[month]} ${year}, ${hour}:${minute}:${second} ${ampm}`;

  return modifiedDate;
};
