import { useCallback, useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import { Card } from "components/card";
import { DataTable } from "components/table";
import { IRDataColumns } from "types/datatable.types";
import { NetworkServices } from "network";
import { networkErrorHandeller } from "utils/helper";
import { PageHeader } from "components/page-header";
import { IIPAddress } from "types/ipaddress.types";
import { Link } from "react-router-dom";
import { WhiteCircleButton } from "components/button";
import { MdEdit } from "react-icons/md";

export const IpAddressIndex: React.FC = (): JSX.Element => {
  const [data, setData] = useState<IIPAddress[] | []>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Ipaddress.index();
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /* data columns */
  const columns: IRDataColumns[] = [
    {
      name: "Address",
      selector: (row) => row.address,
    },
    {
      name: "Action",
      maxWidth: "70px",
      cell: (row) => (
        <Link to={`/ipaddress/edit/${row._id}`}>
          <WhiteCircleButton type="button">
            <MdEdit size={18} />
          </WhiteCircleButton>
        </Link>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title="IP address list"
        redirectPath="/ipaddress/create"
        buttonIcon={<HiPlus size={18} />}
      />

      <Card>
        <DataTable
          data={data}
          columns={columns}
          loading={isLoading}
          pagination={false}
          paginationServer={false}
          noDataMessage="No data found."
        />
      </Card>
    </div>
  );
};
