import { privateRequest } from "config/axios.config";
import { PaginationParamsTypes } from "types/pagination.types";
import { IEmployeeStore, IEmployeeUpdate } from "types/employee.types";

/* List of resources */
export const index = async (reqParams: PaginationParamsTypes) => {
  return await privateRequest.get(`/api/v1/admin/employee`, {
    params: { ...reqParams },
  });
};

/* Store resource */
export const store = async (data: IEmployeeStore) => {
  return await privateRequest.post(`/api/v1/admin/employee`, data);
};

/* Show specific resource */
export const show = async (id: string) => {
  return await privateRequest.get(`/api/v1/admin/employee/${id}`);
};

/* Update specific resource */
export const update = async ({
  id,
  documents,
}: {
  id: string;
  documents: IEmployeeUpdate;
}) => {
  return await privateRequest.put(`/api/v1/admin/employee/${id}`, {
    ...documents,
  });
};

/* Destroy specific resource */
export const destroy = async ({ id }: { id: string }) => {
  return await privateRequest.delete(`/api/v1/admin/employee/${id}`);
};

/* Show specific resource */
export const attendance = async (id: string) => {
  return await privateRequest.get(`/api/v1/admin/employee/${id}/attendance`);
};
