import { useCallback, useEffect, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { Tab } from "@headlessui/react";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { NetworkError } from "components/501";
import { PageHeader } from "components/page-header";
import { networkErrorHandeller } from "utils/helper";
import { ProfileLoader } from "components/ghostloader/profile.ghostloader";
import { AdminAboutTabPanel } from "components/tab-panels/admin/about.tabpanel";
import { AdminSettingTabPanel } from "components/tab-panels/admin/setting.tabpanel";
import { NotFound } from "components/404";
import { IAdmin } from "types/admin.types";
import { Images } from "utils/images";

const tabItems: string[] = ["About Me", "Settings"];

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

export const ProfileIndex: React.FC = (): JSX.Element => {
  const [data, setData] = useState<IAdmin | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Profile.me();
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <PageHeader
        title="Your profile"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/dashboard"
      />

      {isLoading && !serverError && !data ? (
        <Card className="lg:p-5">
          <ProfileLoader />
        </Card>
      ) : null}
      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Profile information not found." />
      ) : null}

      {/* Profile information container */}
      {!isLoading && !serverError && data ? (
        <Card className="lg:p-5 mb-4">
          <div className="lg:flex gap-6">
            <div className="w-[100px] h-[100px] rounded-full overflow-hidden mx-auto lg:mx-0 mb-4 lg:mb-0 border">
              <img
                src={data.image || Images.Avatar}
                alt="Profile avatar"
                className="w-[100px] h-[100px] rounded-full"
              />
            </div>

            <div className="text-center lg:text-left lg:pt-6">
              <p className="text-base font-bold mb-1 text-primary">
                {data.name}
              </p>
              <p className="text-muted text-sm capitalize">{data.role}</p>
            </div>
          </div>
        </Card>
      ) : null}

      {/* Others info tabs */}
      {!isLoading && !serverError && data ? (
        <Card>
          <Tab.Group>
            <Tab.List className="flex max-w-md">
              {tabItems.map((item) => (
                <Tab
                  key={item}
                  className={({ selected }: { selected: any }) =>
                    classNames(
                      "w-full py-2.5 text-sm leading-5 font-medium focus-visible:outline-none text-muted",
                      "capitalize",
                      selected
                        ? "text-black border-b-2 border-b-primary"
                        : "text-muted border-b-2 border-b-none"
                    )
                  }
                >
                  {item}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="my-4 rounded-md overflow-hidden">
              <Tab.Panel>
                <AdminAboutTabPanel {...data} />
              </Tab.Panel>
              <Tab.Panel>
                <AdminSettingTabPanel {...data} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Card>
      ) : null}
    </>
  );
};
