import { useState } from "react";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { Toastify } from "components/toastify";
import { PageHeader } from "components/page-header";
import { networkErrorHandeller } from "utils/helper";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { AdminForm } from "components/form/admin.form";

export const AdminStore: React.FC = (): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(false);

  /* Handle form submission */
  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);

      const response = await NetworkServices.Admin.store(data);
      if (response && response.status === 201) {
        Toastify.Success(response.data.message);
      }

      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div>
      <PageHeader
        title="Create admin"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/admin"
      />

      <Card className="lg:p-6">
        <AdminForm
          loading={isLoading}
          onSubmit={(data) => handleSubmit(data)}
        />
      </Card>
    </div>
  );
};
