import { Navigate } from "react-router-dom";
import { IAppRoute } from "types/routes";

/* Layouts */
import { DashbaordLayout } from "layouts/dashboard";

/* Dashboard pages */
import { Dashboard } from "pages/dashboard";

/* Admin pages */
import { AdminIndex } from "pages/admin";
import { AdminStore } from "pages/admin/store";
import { AdminShow } from "pages/admin/show";

/* Employee pages */
import { EmployeeIndex } from "pages/employee";
import { EmployeeStore } from "pages/employee/store";
import { EmployeeEdit } from "pages/employee/edit";
import { EmployeeShow } from "pages/employee/show";
import { EmployeeAttendance } from "pages/employee/attendance";

/* IP address pages */
import { IpAddressIndex } from "pages/ipaddress";
import { IpAddressStore } from "pages/ipaddress/store";
import { IpAddressEdit } from "pages/ipaddress/edit";

/* Profile pages */
import { ProfileIndex } from "pages/profile";
import { ProfileChangePassword } from "pages/profile/change-password";
import { getToken } from "utils/helper";

const appRoutes: IAppRoute[] = [
  {
    path: "dashboard",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      { path: "", in_drawer: false, multi_menu: false, element: <Dashboard /> },
    ],
  },
  {
    path: "admin",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <AdminIndex />,
      },
      {
        path: "create",
        in_drawer: false,
        multi_menu: false,
        element: <AdminStore />,
      },
      {
        path: "show/:id",
        in_drawer: false,
        multi_menu: false,
        element: <AdminShow />,
      },
    ],
  },
  {
    path: "employee",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <EmployeeIndex />,
      },
      {
        path: "create",
        in_drawer: false,
        multi_menu: false,
        element: <EmployeeStore />,
      },
      {
        path: "edit/:id",
        in_drawer: false,
        multi_menu: false,
        element: <EmployeeEdit />,
      },
      {
        path: "show/:id",
        in_drawer: false,
        multi_menu: false,
        element: <EmployeeShow />,
      },
      {
        path: "attendance/:id",
        in_drawer: false,
        multi_menu: false,
        element: <EmployeeAttendance />,
      },
    ],
  },
  {
    path: "ipaddress",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <IpAddressIndex />,
      },
      {
        path: "create",
        in_drawer: false,
        multi_menu: false,
        element: <IpAddressStore />,
      },
      {
        path: "edit/:id",
        in_drawer: false,
        multi_menu: false,
        element: <IpAddressEdit />,
      },
    ],
  },
  {
    path: "profile",
    in_drawer: false,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <ProfileIndex />,
      },
      {
        path: "change-password",
        in_drawer: false,
        multi_menu: false,
        element: <ProfileChangePassword />,
      },
    ],
  },
];

/* Generate permitted routes */
export const permittedRoutes = () => {
  const token = getToken();
  if (token) {
    return appRoutes;
  }

  return [];
};
