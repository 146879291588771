import { PrimaryButton } from "components/button";
import { PasswordInput } from "components/input";
import { useForm } from "react-hook-form";

type PropsTypes = {
  loading: boolean;
  onSubmit: (data: any) => void;
};

export const AdminChangePasswordForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  /* Handle form submit */
  const onSubmit = (data: any) => {
    if (data.new_password !== data.re_typed_new_password) {
      return setError("re_typed_new_password", {
        type: "custom",
        message: "The passwords do not match.",
      });
    }
    props.onSubmit(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Old password input control */}
        <div className="mb-6">
          <PasswordInput
            label="Old password"
            name="old_password"
            type="text"
            placeholder="********"
            control={control}
            error={errors.old_password && errors.old_password.message}
            defaultvalue={""}
            rules={{ required: "Old password is required" }}
          />
        </div>

        {/* New password input control */}
        <div className="mb-6">
          <PasswordInput
            label="New password"
            name="new_password"
            type="text"
            placeholder="********"
            control={control}
            error={errors.new_password && errors.new_password.message}
            defaultvalue={""}
            rules={{ required: "New password is required" }}
          />
        </div>

        {/* Confirm password input control */}
        <div className="mb-6">
          <PasswordInput
            label="Confirm password"
            name="re_typed_new_password"
            type="text"
            placeholder="********"
            control={control}
            error={
              errors.re_typed_new_password &&
              errors.re_typed_new_password.message
            }
            defaultvalue={""}
            rules={{ required: "Confirm password is required" }}
          />
        </div>

        {/* Submit button */}
        <div className="text-right">
          <PrimaryButton type="submit" disabled={props.loading}>
            {props.loading ? "Loading..." : "Submit"}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};
