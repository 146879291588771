import { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { Toastify } from "components/toastify";
import { PageHeader } from "components/page-header";
import { networkErrorHandeller } from "utils/helper";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { EmployeeForm } from "components/form/employee.form";
import { FormLoader } from "components/ghostloader/form.ghostloader";
import { IEmployee } from "types/employee.types";
import { useParams } from "react-router-dom";
import { NotFound } from "components/404";
import { NetworkError } from "components/501";

export const EmployeeEdit: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [data, setData] = useState<IEmployee | null>();
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Employee.show(id || "");
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /* Handle form submission */
  const handleSubmit = async (data: any) => {
    try {
      setUpdate(true);

      const response = await NetworkServices.Employee.update({
        id: id || "",
        documents: data,
      });
      if (response && response.status === 200) {
        Toastify.Success(response.data.message);
      }

      setUpdate(false);
    } catch (error: any) {
      if (error) {
        setUpdate(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div>
      <PageHeader
        title="Edit employee information"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/employee"
      />

      {isLoading && !serverError && !data ? (
        <Card className="lg:p-5">
          <FormLoader />
        </Card>
      ) : null}
      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Employee not found." />
      ) : null}

      {!isLoading && !serverError && data ? (
        <Card className="lg:p-6">
          <EmployeeForm
            data={data}
            formType="edit"
            loading={isUpdate}
            onSubmit={(data) => handleSubmit(data)}
          />
        </Card>
      ) : null}
    </div>
  );
};
