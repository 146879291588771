import { useState } from "react";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { Toastify } from "components/toastify";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "components/page-header";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { networkErrorHandeller, removeToken } from "utils/helper";
import { AdminChangePasswordForm } from "components/form/change-password.form";

export const ProfileChangePassword: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  /* Handle submit */
  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);
      const response = await NetworkServices.Profile.changePassword(data);
      if (response && response.status === 200) {
        Toastify.Success(response.data.message);
        removeToken();
        navigate("/");
      }

      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <>
      <PageHeader
        title="Change account password"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/dashboard"
      />

      <Card className="md:max-w-md lg:p-5">
        <AdminChangePasswordForm
          loading={isLoading}
          onSubmit={(data) => handleSubmit(data)}
        />
      </Card>
    </>
  );
};
