import * as Authentication from "network/auth.network";
import * as Admin from "network/admin.network";
import * as Employee from "network/employee.network";
import * as Ipaddress from "network/ipaddress.network";
import * as Profile from "network/profile.network";
import * as Dashboard from "network/dashboard.network";
import * as FileUpload from "network/fileuplod.network";

export const NetworkServices = {
  Authentication,
  Admin,
  Employee,
  Ipaddress,
  Profile,
  Dashboard,
  FileUpload,
};
