import { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { useParams } from "react-router-dom";
import { DataTable } from "components/table";
import { PageHeader } from "components/page-header";
import { dateTimeParse, networkErrorHandeller } from "utils/helper";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { IRDataColumns } from "types/datatable.types";
import { IEmployeeAttendance } from "types/employee.types";

export const EmployeeAttendance: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [data, setData] = useState<IEmployeeAttendance[] | []>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Employee.attendance(id || "");
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /* data columns */
  const columns: IRDataColumns[] = [
    {
      name: "IP",
      selector: (row) => row.ip_address,
    },
    {
      name: "IMEI",
      selector: (row) => row.device_imei,
    },
    {
      name: "Check in",
      selector: (row) => dateTimeParse(row.logged_in_at),
    },
    {
      name: "Check out",
      selector: (row) => dateTimeParse(row.logged_out_at),
    },
  ];

  return (
    <div>
      <PageHeader
        title="Attendance information"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/employee"
      />

      <Card>
        <DataTable
          data={data}
          columns={columns}
          loading={isLoading}
          pagination={false}
          paginationServer={false}
          noDataMessage="No data found."
        />
      </Card>
    </div>
  );
};
