import { IAdmin } from "types/admin.types";

export const AdminAboutTabPanel: React.FC<IAdmin> = (
  props: IAdmin
): JSX.Element => {
  return (
    <div className="w-full pt-2">
      <p className="text-base font-medium mb-4 text-primary">
        Personal information.
      </p>

      <div className="w-full lg:max-w-lg flex">
        <div className="w-[150px]">
          <p className="text-sm mb-3 text-muted">ID</p>
          <p className="text-sm mb-3 text-muted">Name</p>
          <p className="text-sm mb-3 text-muted">Email</p>
          <p className="text-sm mb-3 text-muted">Phone</p>
          <p className="text-sm mb-3 text-muted">Gender</p>
          <p className="text-sm mb-3 text-muted">Role</p>
        </div>
        <div className="grow">
          <p className="text-sm mb-3 text-muted">: {props.employee_id}</p>
          <p className="text-sm mb-3 text-muted">: {props.name}</p>
          <p className="text-sm mb-3 text-muted">: {props.email}</p>
          <p className="text-sm mb-3 text-muted">
            : {props.phone_dial_code + "" + props.phone}
          </p>
          <p className="text-sm mb-3 text-muted">: {props.gender}</p>
          <p className="text-sm mb-3 text-muted capitalize">: {props.role}</p>
        </div>
      </div>
    </div>
  );
};
