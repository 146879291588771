import { useCallback, useEffect, useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { Images } from "utils/images";
import { removeToken } from "utils/helper";
import { NetworkServices } from "network";
import { networkErrorHandeller } from "utils/helper";
import { IAdmin } from "types/admin.types";

export const ProfileDropdown: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [data, setData] = useState<IAdmin | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<boolean>(false);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Profile.me();
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /* Handle logout */
  const handleLogout = () => {
    removeToken();
    navigate("/");
  };

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div className="inline-flex">
          <Menu.Button className="rounded-full mb-0">
            {isLoading && !serverError && !data ? (
              <div className="rounded-full w-[43px] h-[43px] bg-gray-200" />
            ) : null}

            {!isLoading && !data && serverError ? (
              <img
                src={Images.Avatar}
                alt="Avatar"
                className="rounded-full w-[43px] h-[43px]"
              />
            ) : null}

            {!isLoading && !serverError && data ? (
              <img
                src={data.image || Images.Avatar}
                alt="Avatar"
                className="rounded-full w-[43px] h-[43px]"
              />
            ) : null}
          </Menu.Button>
          <div className="pt-3 pl-2">
            {isLoading && !serverError && !data ? (
              <div className="rounded-full w-[100px] h-[16px] bg-gray-200" />
            ) : null}

            {!isLoading && !data && serverError ? (
              <p className="text-sm font-medium text-muted">Welcome</p>
            ) : null}

            {!isLoading && !serverError && data ? (
              <p className="text-sm font-medium text-muted">
                Welcome {data.name.split(" ")[0]}
              </p>
            ) : null}
          </div>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 w-[200px] mt-2 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg">
            <div className="p-3">
              <Menu.Item>
                <Link to="/profile">
                  <button className="px-3 py-2 w-full font-medium text-[15px] rounded-md text-indigo-500 hover:bg-indigo-100 transition ease-in-out delay-50 text-left">
                    Profile
                  </button>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/profile/change-password">
                  <button className="px-3 py-2 w-full font-medium text-[15px] rounded-md text-indigo-500 hover:bg-indigo-100 transition ease-in-out delay-50 text-left">
                    Change password
                  </button>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={handleLogout}
                  className="px-3 py-2 w-full font-medium text-[15px] rounded-md text-indigo-500 hover:bg-indigo-100 transition ease-in-out delay-50 text-left"
                >
                  Logout
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
