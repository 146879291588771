import { useForm } from "react-hook-form";
import { PrimaryButton } from "components/button";
import { isValidEmail, isValidPhone, genderList } from "utils/helper";
import { TextInput, PasswordInput, SingleSelect } from "components/input";

type PropsTypes = {
  loading: boolean;
  onSubmit: (data: any) => void;
};

export const AdminForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /* Handle form submit */
  const onSubmit = (data: any) => {
    const formData = {
      ...data,
      gender: data.gender.value,
      phone_country: "Bangladesh",
      phone_dial_code: "+880",
      phone: data.phone.slice(4, 14),
    };

    props.onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6">
        {/* employee_id input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Employee ID"
            name="employee_id"
            type="text"
            placeholder="EX: GV001"
            control={control}
            error={errors.employee_id && errors.employee_id.message}
            defaultvalue={""}
            rules={{ required: "Employee ID is required" }}
          />
        </div>

        {/* Name input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Employee name"
            name="name"
            type="text"
            placeholder="Enter employee name"
            control={control}
            error={errors.name && errors.name.message}
            defaultvalue={""}
            rules={{ required: "Employee name is required" }}
          />
        </div>

        {/* Email input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Email address"
            name="email"
            type="text"
            placeholder="Enter email address"
            control={control}
            error={errors.email && errors.email.message}
            defaultvalue={""}
            rules={{
              required: "Email is required.",
              pattern: {
                value: isValidEmail(),
                message: "Invalid email address.",
              },
            }}
          />
        </div>

        {/* Phone input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Phone number"
            name="phone"
            type="text"
            placeholder="+880-1XXX-XXX-XXX"
            control={control}
            error={errors.phone && errors.phone.message}
            defaultvalue={"+880"}
            rules={{
              required: "Phone is required.",
              pattern: {
                value: isValidPhone(),
                message: "Invalid phone number.",
              },
            }}
          />
        </div>

        {/* Gender select control */}
        <div className="mb-6 lg:mb-2">
          <SingleSelect
            label="Gender"
            name="gender"
            control={control}
            error={errors.gender && errors.gender.message}
            defaultvalue={null}
            options={genderList}
            isClearable={true}
            placeholder="Select gender"
            rules={{ required: "Gender is required" }}
          />
        </div>

        {/* Password input control */}
        <div className="mb-6">
          <PasswordInput
            label="Password"
            name="password"
            type="text"
            placeholder="********"
            control={control}
            error={errors.password && errors.password.message}
            defaultvalue={""}
            rules={{ required: "Password is required" }}
          />
        </div>
      </div>

      {/* Submit button */}
      <div className="text-right lg:mb-14">
        <PrimaryButton type="submit" disabled={props.loading}>
          {props.loading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
};
