import { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { PageHeader } from "components/page-header";
import { networkErrorHandeller } from "utils/helper";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { ProfileLoader } from "components/ghostloader/profile.ghostloader";
import { IEmployee } from "types/employee.types";
import { useParams } from "react-router-dom";
import { NotFound } from "components/404";
import { NetworkError } from "components/501";
import { Images } from "utils/images";

export const EmployeeShow: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [data, setData] = useState<IEmployee | null>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Employee.show(id || "");
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <PageHeader
        title="Employee information"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/employee"
      />

      {isLoading && !serverError && !data ? (
        <Card className="lg:p-5">
          <ProfileLoader />
        </Card>
      ) : null}
      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Employee not found." />
      ) : null}

      {/* Profile information container */}
      {!isLoading && !serverError && data ? (
        <Card className="lg:p-5 mb-4">
          <div className="lg:flex gap-6">
            <div className="w-[100px] h-[100px] rounded-full overflow-hidden mx-auto lg:mx-0 mb-4 lg:mb-0 border">
              <img
                src={data.image || Images.Avatar}
                alt="Profile avatar"
                className="w-[100px] h-[100px] rounded-full"
              />
            </div>

            <div className="text-center lg:text-left lg:pt-6">
              <p className="text-base font-bold mb-1 text-primary">
                {data.name}
              </p>
              <p className="text-muted text-sm">{data.designation}</p>
            </div>
          </div>
        </Card>
      ) : null}

      {/* Others information */}
      {!isLoading && !serverError && data ? (
        <Card className="lg:p-5 mb-4">
          <div className="w-full pt-2">
            <p className="text-base font-medium mb-4 text-primary">
              Personal information.
            </p>

            <div className="w-full lg:max-w-lg flex">
              <div className="w-[150px]">
                <p className="text-sm mb-3 text-muted">Name</p>
                <p className="text-sm mb-3 text-muted">Email</p>
                <p className="text-sm mb-3 text-muted">Phone</p>
                <p className="text-sm mb-3 text-muted">Gender</p>
                <p className="text-sm mb-3 text-muted">Department</p>
                <p className="text-sm mb-3 text-muted">Designation</p>
                <p className="text-sm mb-3 text-muted">Device IMEI</p>
              </div>
              <div className="grow">
                <p className="text-sm mb-3 text-muted">: {data.name}</p>
                <p className="text-sm mb-3 text-muted">: {data.email}</p>
                <p className="text-sm mb-3 text-muted">
                  : {data.phone_dial_code + "" + data.phone}
                </p>
                <p className="text-sm mb-3 text-muted">: {data.gender}</p>

                <p className="text-sm mb-3 text-muted">: {data.department}</p>
                <p className="text-sm mb-3 text-muted">: {data.designation}</p>
                <p className="text-sm mb-3 text-muted">: {data.imei}</p>
              </div>
            </div>
          </div>
        </Card>
      ) : null}
    </div>
  );
};
