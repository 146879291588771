import { useState } from "react";
import { NetworkServices } from "network";
import { IAdmin } from "types/admin.types";
import { Toastify } from "components/toastify";
import { networkErrorHandeller } from "utils/helper";
import { AdminProfileForm } from "components/form/profile.form";

export const AdminSettingTabPanel: React.FC<IAdmin> = (
  props: IAdmin
): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(false);

  /* Handle submit */
  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);
      const response = await NetworkServices.Profile.update(data);
      if (response && response.status === 200) {
        setLoading(false);
        Toastify.Success(response.data.message);
      }
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div className="pt-2">
      <p className="text-base font-medium mb-4 text-primary">
        Account settings.
      </p>

      <div>
        <AdminProfileForm
          data={props}
          loading={isLoading}
          onSubmit={(data) => handleSubmit(data)}
        />
      </div>
    </div>
  );
};
