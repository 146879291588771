import React from "react"
import { NotFound } from "components/404"

export const FourOFour: React.FC = (): JSX.Element => {
    return (
        <div className="grid h-screen place-items-center">
            <NotFound message="What are you looking for? Page not found." />
        </div>
    );
};
