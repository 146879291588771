import { useForm } from "react-hook-form";
import { TextInput } from "components/input";
import { PrimaryButton } from "components/button";
import { Link } from "react-router-dom";

type PropsTypes = {
  loading: boolean;
  onSubmit: (data: any) => void;
};

export const ResetForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /* Handle form submit */
  const onSubmit = (data: any) => props.onSubmit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Employee ID input control */}
      <div className="mb-6">
        <TextInput
          label="Employee ID"
          name="employee_id"
          type="text"
          placeholder="EMP00000001"
          control={control}
          error={errors.employee_id && errors.employee_id.message}
          defaultvalue={""}
          rules={{ required: "Employee ID is required" }}
        />
      </div>

      {/* Submit button */}
      <div className="text-center mb-4">
        <PrimaryButton
          type="submit"
          disabled={props.loading}
          className="!px-8"
        >
          {props.loading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>

      {/* Another page links */}
      <div className="text-center mb-4">
        <Link to="/">
          <p className="text-sm font-medium text-muted hover:text-primary">
            Back to login.
          </p>
        </Link>
      </div>
    </form>
  );
};
