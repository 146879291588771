import { publicRequest } from "config/axios.config";

/* Login request */
type LoginDataType = {
  employee_id: string;
  password: string;
};
export const login = async (data: LoginDataType) => {
  return await publicRequest.post(`/api/v1/admin/auth/login`, data);
};

/* Reset request */
type ResetDataType = {
  employee_id: string;
};
export const reset = async (data: ResetDataType) => {
  return await publicRequest.post(`/api/v1/admin/auth/reset`, data);
};

/* Verify otp request */
type VerifyDataType = {
  employee_id: string;
  otp_code: string;
};
export const verifyOtp = async (data: VerifyDataType) => {
  return await publicRequest.post(`/api/v1/admin/auth/verify-otp`, data);
};

/* Set password request */
type SetPasswordDataType = {
  employee_id: string;
  otp_code: string;
  password: string;
  c_password: string;
};
export const setPassword = async (data: SetPasswordDataType) => {
  return await publicRequest.post(`/api/v1/admin/auth/set-password`, data);
};
