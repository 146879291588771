import React, { useState } from "react";
import { Images } from "utils/images";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { Toastify } from "components/toastify";
import { networkErrorHandeller } from "utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import { SetPasswordForm } from "components/form/set-password.form";

export const SetPassword: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { employeeid, otp } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);

  /* Handle form submit */
  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const formData = {
        ...data,
        employee_id: employeeid || "",
        otp_code: otp || "",
      };

      const response = await NetworkServices.Authentication.setPassword(
        formData
      );
      if (response && response.status === 200) {
        Toastify.Success(response.data.message);
        navigate("/");
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      networkErrorHandeller(error);
    }
  };

  return (
    <div className="container mx-auto">
      <div className="p-4 mt-7 lg:mt-16">
        <div className="w-full sm:w-[500px] mx-auto">
          <Card className="p-6">
            <div className="text-center mb-8">
              <img
                src={Images.Logo}
                alt="Logo"
                className="w-[101px] h-[66px] mx-auto mb-2"
              />

              <p className="text-2xl mb-1 font-bold">Set password</p>
              <p className="text-center text-sm font-medium text-muted">
                Change your new password.
              </p>
            </div>

            <SetPasswordForm
              loading={isLoading}
              onSubmit={(data) => onSubmit(data)}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
