import { useForm } from "react-hook-form";
import { TextInput } from "components/input";
import { PrimaryButton } from "components/button";
import { IIPAddress } from "types/ipaddress.types";

type PropsTypes = {
  loading: boolean;
  formType: "store" | "edit";
  data: IIPAddress | null;
  onSubmit: (data: any) => void;
};

export const IpAddressForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /* Handle form submit */
  const onSubmit = (data: any) => {
    props.onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* IP address input control */}
      <div className="mb-4">
        <TextInput
          label="IP address"
          name="address"
          type="text"
          placeholder="EX: 103.147.41.146"
          control={control}
          error={errors.address && errors.address.message}
          defaultvalue={props.data?.address || ""}
          rules={{ required: "Address is required" }}
        />
      </div>

      {/* Submit button */}
      <div className="text-right">
        <PrimaryButton type="submit" disabled={props.loading}>
          {props.loading
            ? "Loading..."
            : props.formType === "edit"
            ? "Save Changes"
            : "Create"}
        </PrimaryButton>
      </div>
    </form>
  );
};
