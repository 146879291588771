import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HiPlus, HiOutlineTrash } from "react-icons/hi";
import { FiActivity } from "react-icons/fi";
import { Card } from "components/card";
import { DataTable } from "components/table";
import { IEmployeeList } from "types/employee.types";
import { IRDataColumns } from "types/datatable.types";
import { NetworkServices } from "network";
import { networkErrorHandeller } from "utils/helper";
import { WhiteCircleButton } from "components/button";
import { MdRemoveRedEye, MdEdit } from "react-icons/md";
import { PageHeader } from "components/page-header";
import { Images } from "utils/images";
import { ConfirmationModal } from "components/modal/confirm.modal";
import { Toastify } from "components/toastify";

type DestroyTypes = {
  id: null;
  show: boolean;
  loading: boolean;
};

export const EmployeeIndex: React.FC = (): JSX.Element => {
  const [data, setData] = useState<IEmployeeList[] | []>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [willDestroy, setWillDestroy] = useState<DestroyTypes>({
    id: null,
    show: false,
    loading: false,
  });

  /* Fetch data */
  const fetchData = useCallback(
    async (page: number) => {
      try {
        setLoading(true);
        const response = await NetworkServices.Employee.index({
          page,
          limit: perPage,
        });
        if (response && response.status === 200) {
          setData(response?.data?.data);
          setTotalRows(response?.data?.pagination?.total_items);
        }
        setLoading(false);
      } catch (error: any) {
        if (error) {
          setLoading(false);
          networkErrorHandeller(error);
        }
      }
    },
    [perPage]
  );

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  /* handle paginate page change */
  const handlePageChange = (page: number) => fetchData(page);

  /* handle paginate row change */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLoading(true);
    const response = await NetworkServices.Employee.index({
      page,
      limit: newPerPage,
    });
    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  /* handle destroy */
  const handleDestroy = async () => {
    try {
      setWillDestroy({ ...willDestroy, loading: true });
      const response = await NetworkServices.Employee.destroy({
        id: willDestroy.id || "",
      });

      if (response && response.status === 200) {
        fetchData(1);
        Toastify.Success(response.data.message);
      }

      setWillDestroy({ id: null, show: false, loading: false });
    } catch (error: any) {
      setWillDestroy({ id: null, loading: false, show: false });
      networkErrorHandeller(error);
    }
  };

  /* data columns */
  const columns: IRDataColumns[] = [
    {
      name: "",
      maxWidth: "60px",
      cell: (row) => (
        <img
          src={row.image || Images.Avatar}
          alt="Employee avatar"
          className="w-[50px] h-[50px] rounded-full mx-auto"
        />
      ),
    },
    {
      name: "ID",
      maxWidth: "150px",
      selector: (row) => row.employee_id,
    },
    {
      name: "Name",
      cell: (row) => (
        <div>
          <p className="font-medium text-sm">{row.name}</p>
          <span className=" text-muted">{row.designation}</span>
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => `${row.phone_dial_code}${row.phone}`,
    },
    {
      name: "Action",
      maxWidth: "190px",
      cell: (row) => (
        <div className="flex gap-1">
          <Link to={`/employee/attendance/${row._id}`}>
            <WhiteCircleButton type="button">
              <FiActivity size={18} />
            </WhiteCircleButton>
          </Link>
          <Link to={`/employee/show/${row._id}`}>
            <WhiteCircleButton type="button">
              <MdRemoveRedEye size={18} />
            </WhiteCircleButton>
          </Link>
          <Link to={`/employee/edit/${row._id}`}>
            <WhiteCircleButton type="button">
              <MdEdit size={18} />
            </WhiteCircleButton>
          </Link>
          <WhiteCircleButton
            type="button"
            onClick={() =>
              setWillDestroy({ id: row._id, show: true, loading: false })
            }
          >
            <HiOutlineTrash size={18} className="text-danger" />
          </WhiteCircleButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title="Emplotyee list"
        redirectPath="/employee/create"
        buttonIcon={<HiPlus size={18} />}
      />

      <Card>
        <DataTable
          data={data}
          columns={columns}
          loading={isLoading}
          pagination={true}
          paginationServer={true}
          totalRows={totalRows}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          noDataMessage="No data found."
        />
      </Card>

      {/* Destroy confirmation modal */}
      <ConfirmationModal
        show={willDestroy.show}
        title="Are you sure you want to delete?"
        loading={willDestroy.loading}
        onHide={() => setWillDestroy({ id: null, loading: false, show: false })}
        onConfirm={handleDestroy}
      />
    </div>
  );
};
