import { privateRequest } from "config/axios.config";
import { IAdminCreate } from "types/admin.types";
import { PaginationParamsTypes } from "types/pagination.types";

/* List of resources */
export const index = async (reqParams: PaginationParamsTypes) => {
  return await privateRequest.get(`/api/v1/admin/admin`, {
    params: { ...reqParams },
  });
};

/* Store resource */
export const store = async (data: IAdminCreate) => {
  return await privateRequest.post(`/api/v1/admin/admin`, data);
};

/* Show specific resource */
export const show = async (id: string) => {
  return await privateRequest.get(`/api/v1/admin/admin/${id}`);
};

/* Destroy specific resource */
export const destroy = async ({ id }: { id: string }) => {
  return await privateRequest.delete(`/api/v1/admin/admin/${id}`);
};
