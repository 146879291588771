import React, { useState } from "react";
import { Images } from "utils/images";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { Toastify } from "components/toastify";
import { useNavigate } from "react-router-dom";
import { networkErrorHandeller } from "utils/helper";
import { ResetForm } from "components/form/reset.form";

export const Reset: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  /* handle submit */
  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);

      const response = await NetworkServices.Authentication.reset(data);
      if (response && response.status === 200) {
        Toastify.Success(response.data.message);
        navigate(`/otp-verification/${data.employee_id}`);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      networkErrorHandeller(error);
    }
  };

  return (
    <div className="container mx-auto">
      <div className="p-4 mt-7 lg:mt-16">
        <div className="w-full sm:w-[500px] mx-auto">
          <Card className="p-6">
            <div className="text-center mb-8">
              <img
                src={Images.Logo}
                alt="Logo"
                className="w-[101px] h-[66px] mx-auto mb-2"
              />

              <p className="text-2xl font-bold">Reset Password</p>
              <p className="text-center text-sm font-medium text-muted">
                Just enter employee ID, instructions will send.
              </p>
            </div>
            <ResetForm
              loading={isLoading}
              onSubmit={(data) => handleSubmit(data)}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
