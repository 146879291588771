import { useForm } from "react-hook-form";
import { PrimaryButton } from "components/button";
import { isValidEmail, isValidPhone, genderList } from "utils/helper";
import { TextInput, PasswordInput, SingleSelect } from "components/input";
import { IEmployee } from "types/employee.types";

type PropsTypes = {
  loading: boolean;
  formType: "store" | "edit";
  data: IEmployee | null;
  onSubmit: (data: any) => void;
};

export const EmployeeForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /* Handle form submit */
  const onSubmit = (data: any) => {
    const formData = {
      ...data,
      gender: data.gender.value,
      phone_country: "Bangladesh",
      phone_dial_code: "+880",
      phone: data.phone.slice(4, 14),
    };
    props.onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6">
        {/* employee_id input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Employee ID"
            name="employee_id"
            type="text"
            placeholder="EX: GV001"
            control={control}
            error={errors.employee_id && errors.employee_id.message}
            defaultvalue={props.data?.employee_id || ""}
            rules={{ required: "Employee ID is required" }}
          />
        </div>

        {/* Name input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Employee name"
            name="name"
            type="text"
            placeholder="Enter employee name"
            control={control}
            error={errors.name && errors.name.message}
            defaultvalue={props.data?.name || ""}
            rules={{ required: "Employee name is required" }}
          />
        </div>

        {/* Email input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Email address"
            name="email"
            type="text"
            placeholder="Enter email address"
            control={control}
            error={errors.email && errors.email.message}
            defaultvalue={props.data?.email || ""}
            rules={{
              required: "Email is required.",
              pattern: {
                value: isValidEmail(),
                message: "Invalid email address.",
              },
            }}
          />
        </div>

        {/* Phone input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Phone number"
            name="phone"
            type="text"
            placeholder="+880-1XXX-XXX-XXX"
            control={control}
            error={errors.phone && errors.phone.message}
            defaultvalue={
              props.data
                ? props.data.phone_dial_code + props.data.phone
                : "+880"
            }
            rules={{
              required: "Phone is required.",
              pattern: {
                value: isValidPhone(),
                message: "Invalid phone number.",
              },
            }}
          />
        </div>

        {/* Gender select control */}
        <div className="mb-6 lg:mb-2">
          <SingleSelect
            label="Gender"
            name="gender"
            control={control}
            error={errors.gender && errors.gender.message}
            defaultvalue={
              props.data
                ? { label: props.data.gender, value: props.data.gender }
                : null
            }
            options={genderList}
            isClearable={true}
            placeholder="Select gender"
            rules={{ required: "Gender is required" }}
          />
        </div>

        {/* Department input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Department"
            name="department"
            type="text"
            placeholder="Work department"
            control={control}
            error={errors.department && errors.department.message}
            defaultvalue={props.data?.department || ""}
            rules={{
              required: "Department is required.",
            }}
          />
        </div>

        {/* Designation input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Designation"
            name="designation"
            type="text"
            placeholder="Enter designation"
            control={control}
            error={errors.designation && errors.designation.message}
            defaultvalue={props.data?.designation || ""}
            rules={{
              required: "Designation is required.",
            }}
          />
        </div>

        {/* Device imei input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Device imei"
            name="imei"
            type="text"
            placeholder="Enter imei number"
            control={control}
            error={errors.imei && errors.imei.message}
            defaultvalue={props.data?.imei || ""}
            rules={{
              required: "Device imei is required.",
            }}
          />
        </div>

        {/* Password input control */}
        {props.formType === "store" ? (
          <div className="mb-6">
            <PasswordInput
              label="Password"
              name="password"
              type="text"
              placeholder="********"
              control={control}
              error={errors.password && errors.password.message}
              defaultvalue={""}
              rules={{ required: "Password is required" }}
            />
          </div>
        ) : null}
      </div>

      {/* Submit button */}
      <div className="text-right">
        <PrimaryButton type="submit" disabled={props.loading}>
          {props.loading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
};
