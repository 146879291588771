import { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { NotFound } from "components/404";
import { useParams } from "react-router-dom";
import { NetworkError } from "components/501";
import { Toastify } from "components/toastify";
import { IIPAddress } from "types/ipaddress.types";
import { PageHeader } from "components/page-header";
import { networkErrorHandeller } from "utils/helper";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { IpAddressForm } from "components/form/ipaddress.form";
import { FormLoader } from "components/ghostloader/form.ghostloader";

export const IpAddressEdit: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [data, setData] = useState<IIPAddress | null>(null);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Ipaddress.show(id || "");
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /* handle form submission */
  const handleSubmit = async (data: any) => {
    try {
      setUpdate(true);
      const response = await NetworkServices.Ipaddress.update({
        id: id || "",
        documents: data,
      });
      if (response && response.status === 200) {
        Toastify.Success(response.data.message);
      }
      setUpdate(false);
    } catch (error: any) {
      setUpdate(false);
      networkErrorHandeller(error);
    }
  };

  return (
    <div>
      <PageHeader
        title="Edit address"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/ipaddress"
      />

      {isLoading && !serverError && !data ? (
        <Card className="lg:p-5">
          <FormLoader />
        </Card>
      ) : null}
      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Employee not found." />
      ) : null}

      {!isLoading && !serverError && data ? (
        <div className="w-full md:w-[550px]">
          <Card className="p-6">
            <IpAddressForm
              data={data}
              formType="edit"
              loading={isUpdate}
              onSubmit={handleSubmit}
            />
          </Card>
        </div>
      ) : null}
    </div>
  );
};
