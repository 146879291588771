import { useCallback, useEffect, useState } from "react";
import { NetworkServices } from "network";
import { useNavigate } from "react-router-dom";
import { Card } from "components/card";
import { Toastify } from "components/toastify";
import { LoginForm } from "components/form/login.form";
import { networkErrorHandeller, setToken, getToken } from "utils/helper";
import { Images } from "utils/images";

export const Login: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  /* Handle login */
  const handleLogin = async (data: any) => {
    try {
      setLoading(true);
      const response = await NetworkServices.Authentication.login(data);

      if (response && response.status === 200 && response.data.status) {
        await setToken(response.data.token);
        Toastify.Success(response.data.message);

        navigate("/dashboard");
      }

      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  };

  /* Check stored token */
  const isTokenStored = useCallback(async () => {
    const storedToken = await getToken();
    if (storedToken) {
      navigate("/dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    isTokenStored();
  }, [isTokenStored]);

  return (
    <div className="container mx-auto">
      <div className="p-4 mt-7 lg:mt-16">
        <div className="w-full sm:w-[500px] mx-auto">
          <Card className="p-6">
            <div className="text-center mb-8">
              <img
                src={Images.Logo}
                alt="Logo"
                className="w-[101px] h-[66px] mx-auto mb-2"
              />

              <p className="text-2xl font-bold">Login</p>
              <p className="text-center text-sm font-medium text-muted">
                Login as administratior.
              </p>
            </div>
            <LoginForm
              loading={isLoading}
              onSubmit={(data) => handleLogin(data)}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
