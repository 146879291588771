import { privateRequest } from "config/axios.config";
import { IAdmin, IChangePassword } from "types/admin.types";

/* Account information */
export const me = async () => {
  return await privateRequest.get(`/api/v1/admin/profile`);
};

/* Update account information */
export const update = async (data: IAdmin) => {
  return await privateRequest.put(`/api/v1/admin/profile`, data);
};

/* Change account password */
export const changePassword = async (data: IChangePassword) => {
  return await privateRequest.post(
    `/api/v1/admin/profile/change-password`,
    data
  );
};
