import { useState } from "react";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { Toastify } from "components/toastify";
import { PageHeader } from "components/page-header";
import { networkErrorHandeller } from "utils/helper";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { IpAddressForm } from "components/form/ipaddress.form";

export const IpAddressStore: React.FC = (): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(false);

  /* handle form submission */
  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);
      const response = await NetworkServices.Ipaddress.store(data);
      if (response && response.status === 201) {
        Toastify.Success(response.data.message);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      networkErrorHandeller(error);
    }
  };

  return (
    <div>
      <PageHeader
        title="Create address"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/ipaddress"
      />

      <div className="w-full md:w-[550px]">
        <Card className="p-6">
          <IpAddressForm
            formType="store"
            data={null}
            loading={isLoading}
            onSubmit={handleSubmit}
          />
        </Card>
      </div>
    </div>
  );
};
